<template>
  <div class="">
    <div
      class="card"
      style="
              overflow-y:auto;
            "
    >
      <div
        class="pl-3 pr-3 pb-2 pt-2"
      >
        <div
          class="row saj-title pl-1"
          style=""
        >
          {{ $t("Training & Development Setting") }}
        </div>
        <div
          class=""
        >
          <div
            class="row"
          >
            <div
              class=" col-lg saj-text d-flex justify-content-start mt-1 mb-1"
            >
              {{ $t("Please select best evaluation method for each company") }}:
            </div>
          </div>
          <div class="mt-1 saj-title d-flex justify-content-end">
            <b-button
              class=""
              variant="primary"
              style="
                        color: white !important;
                        "
              @click="updateTopN()"
            >
              {{ $t("Save") }}
            </b-button>
          </div>
          <div class="row mt-1 pl-1 pr-1">
            <b-table
              class="styleTable"
              style=""
              :items="supeHRList"
              :fields="fields"
              label-size="lg"
              bordered
            >
              <template #head()="data">
                <span
                  class="saj-text d-flex justify-content-center"
                >{{ $t(data.label) }}</span>
              </template>
              <template #cell(index)="data">
                <div
                  class="saj-text d-flex justify-content-center"
                >
                  {{ currentPage === 1 ? data.index + 1 : (data.index + 1)+((currentPage) *10) }}
                </div>
              </template>
              <template #cell(subsidiary_name)="data">
                <div
                  class="saj-text d-flex justify-content-start"
                >
                  {{ data.item.subsidiary_name }}
                </div>
              </template>
              <template #cell(top_n)="data">
                <div
                  class="saj-text d-flex justify-content-center"
                  style=""
                >
                  <div
                    class="row mb-2"
                  >
                    <div class="col-lg saj-text">
                      <div class="m-0 mt-1 mb-1">
                        {{ $t("Best of") }}:
                      </div>
                      <b-form-spinbutton
                        v-model="data.item.top_n"
                        variant="primary"
                        inline
                      />
                    </div>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <!-- <div class="mt-1 saj-title d-flex justify-content-end">
            <b-button
              class=""
              variant="primary"
              style="
                        color: white !important;
                        "
              @click="updateTopN()"
            >
              {{ $t("Save") }}
            </b-button>
          </div> -->
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {
  BTable,
  BFormSpinbutton,
  BButton,
} from "bootstrap-vue"
import { required } from '@validations'
import { mapGetters } from 'vuex'
import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
    BTable,
    BFormSpinbutton,
    BButton,
  },

  props: {
    subsidiary: {
      type: Number,
      default: null,
    },
  },

  data(){
    return {
      currentPage: 1,
      required,
      // value: null,
      top_n: null,
      subsidiary_name: "",
      supeHRList: [],
      HrList: [],
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center',
        },
        {
          key: 'subsidiary_name', label: 'Company', tdClass: 'text-center',
        },
        {
          key: 'top_n', label: 'Evaluation Method', tdClass: 'text-center',
        },
      ],
    }
  },

  computed: {
    ...mapGetters(['roles']),
  },

  mounted(){
    // this.getListSuperHR()
    // this.getListHR()
    this.checkUser()
  },

  methods: {
    checkUser() {
      const currentRole = this.roles.selectedRole
      if (currentRole === 6){
        this.getListHR()
      } else if (currentRole === 1){
        this.getListSuperHR()
      }
    },
    updateTopN() {
      const data = new FormData()
      this.supeHRList.forEach(item => {
        data.append(`subsidiary_id[]`, item.subsidiary_id)
        data.append(`new_top_n[${item.subsidiary_id}]`, item.top_n)
      })

      this.$axios.post(`${this.$baseUrl}/top_n/multiple_update_subsidiary_top_n`, data).then(() => {
        this.checkUser()
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$i18n.t(`You have set Training and Development setting`)}.`,
              icon: 'AlertCircleIcon',
              iconBg: "#53b7db",
              variant: 'success',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            info: 'info',
          },
        )
      })
    },
    getListSuperHR() {
      this.$axios.get(`${this.$baseUrl}/top_n/get_subsidiary_top_n`).then(response => {
        this.supeHRList = response.data.data
      })
    },
    getListHR() {
      if (this.subsidiary !== null) {
        this.$axios.get(`${this.$baseUrl}/top_n/get_subsidiary_top_n?subsidiary_id=${this.subsidiary}`).then(response => {
          this.supeHRList = response.data.data
        })
      }
    },
  },
}
</script>
<style>
</style>
