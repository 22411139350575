<template>
  <div
    class=""
  >
    <div
      class="card"
      style="background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 10px;
              overflow-y:auto;"
    >
      <div
        class="pl-3 pr-3 pb-2 pt-2"
      >
        <div
          class="row saj-title pl-1"
          style=""
        >
          {{ $t("JD & KRA Setting") }}
        </div>
        <div
          v-if="roles.isHR"
          class="row saj-text mt-1 mb-1 pl-1"
        >
          {{ $t("Choose one") }} (1) {{ $t("Performance Evaluation Form for Non Executive") }}:
        </div>
        <div
          v-else
          class="saj-text mt-1 mb-1"
        >
          <div>{{ $t("Choose one") }} (1) {{ $t("Performance Evaluation Form for Non Executive") }}:</div>
          <div style="color:red">
            {{ $t("Please select one evaluation for each company") }}
          </div>
        </div>
        <div class="mt-1 saj-title d-flex justify-content-end">
          <b-button
            class=""
            variant="primary"
            @click="updateJdKra()"
          >
            {{ $t("Save") }}
          </b-button>
        </div>
        <div
          class=""
        >
          <b-overlay
            :show="show"
            rounded="sm"
          >
            <template #overlay>
              <p>{{ $t('Fetching Data') }}... </p>
            </template>
            <div class="row mt-1 pl-1 pr-1">
              <b-table
                class="styleTable mt-1"
                style=""
                :items="supeHRList"
                :fields="fields"
                label-size="lg"
                bordered
                show-empty
                responsive
              >
                <template #head()="data">
                  <span
                    class="saj-text d-flex justify-content-center"
                  >{{ $t(data.label) }}</span>
                </template>
                <template #cell(index)="data">
                  <div
                    class="saj-text d-flex justify-content-center"
                  >
                    {{ currentPage === 1 ? data.index + 1 : (data.index + 1)+((currentPage) *10) }}
                  </div>
                </template>
                <template #cell(subsidiary_name)="data">
                  <div
                    class="saj-text d-flex justify-content-start"
                  >
                    {{ data.item.subsidiary_name }}
                  </div>
                </template>
                <template #cell(jdkra_id)="data">
                  <div
                    class="saj-text d-flex justify-content-center"
                    style=""
                  >
                    <div
                      class="row"
                    >
                      <div class="col-lg saj-text">
                        <b-row class="pt-1">
                          <div class="saj-text d-flex align-items-center">
                            <b-form-radio
                              v-model="data.item.jdkra_id"
                              plain
                              :name="jd"
                              size="lg"
                              :value="0"
                              class="saj-text"
                            >
                              <h1
                                class="saj-text"
                              >
                                {{ $t("JD") }}
                              </h1>
                            </b-form-radio>
                          </div>
                        </b-row>
                        <b-row>
                          <div class="saj-text d-flex align-items-center">
                            <b-form-radio
                              v-model="data.item.jdkra_id"
                              plain
                              :name="kra"
                              size="lg"
                              :value="1"
                              class="saj-text"
                            >
                              <h1 class="saj-text">
                                {{ $t("KRA") }}

                              </h1>
                            </b-form-radio>
                          </div>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </b-overlay>
          <!-- <div class="mt-1 saj-title d-flex justify-content-end">
            <b-button
              class=""
              variant="primary"
              @click="updateJdKra()"
            >
              {{ $t("Save") }}
            </b-button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BFormRadio,
  BRow,
  BTable,
  //   BCol,
  BButton,
  BOverlay,
  // BButtonGroup,

} from "bootstrap-vue"
import SAJToast from '@/component/saj-toastification.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    BFormRadio,
    BRow,
    BTable,
    // BCol,
    BButton,
    BOverlay,
    // BButtonGroup,

  },

  props: {
    subsidiary: {
      type: Number,
      default: null,
    },
  },

  data(){
    return {
      tabIndex: 1,
      show: true,
      currentPage: 1,
      // subsidiary_id: null,
      jd_kra: null,
      kra: null,
      jd: null,
      supeHRList: [],
      HrList: [],
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center',
        },
        {
          key: 'subsidiary_name', label: 'Company', tdClass: 'text-center',
        },
        {
          key: 'jdkra_id', label: 'Evaluation Type', tdClass: 'text-center',
        },
      ],
    }
  },

  computed: {
    ...mapGetters(['roles']),
  },

  mounted(){
    // console.log('sini sub id', this.subsidiary)
    this.checkUser()
  },

  methods: {
    checkUser() {
      const currentRole = this.roles.selectedRole
      if (currentRole === 6){
        this.getListHR()
      } else if (currentRole === 1){
        this.getListSuperHR()
      }
    },
    updateJdKra() {
      const data = new FormData()
      this.supeHRList.forEach(item => {
        data.append(`subsidiary_id[]`, item.subsidiary_id)
        // data.append(`jdkra[${item.subsidiary_id}]`, item.jdkra_id)
      })
      this.supeHRList.forEach(item => {
        // data.append(`subsidiary_id[]`, item.subsidiary_id)
        data.append(`jdkra[${item.subsidiary_id}]`, item.jdkra_id)
      })

      this.$axios.post(`${this.$baseUrl}/performancesetting/multiple_setting_form_jdkra_by_subsidiaries`, data).then(() => {
        this.checkUser()
        // this.show = false
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$i18n.t(`You have set JD and KRA setting`)}.`,
              icon: 'CheckIcon',
              iconBg: '#53b7db',
              variant: 'success',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
      })
    },
    getListSuperHR() {
      this.$axios.get(`${this.$baseUrl}/performancesetting/get_multiple_setting_form_jdkra_by_subsidiaries`).then(response => {
        this.supeHRList = response.data.data
        this.show = false
      })
    },
    getListHR() {
      if (this.subsidiary !== null) {
        this.$axios.get(`${this.$baseUrl}/performancesetting/get_multiple_setting_form_jdkra_by_subsidiaries?subsidiary_id=${this.subsidiary}`).then(response => {
          this.supeHRList = response.data.data
          this.show = false
        })
      }
    },
  },
}
</script>
<style>
.radio .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    bottom: 0.5px;
    margin-left: -1.25rem;
}
</style>
