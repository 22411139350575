<template>
  <div class="">
    <div class="row">
      <div class="col-lg">
        <div
          class="card"
          style="
              border-radius: 10px;
            "
        >
          <b-tabs
            v-model="tabIndex"
            class="saj-title p-1"
          >
            <b-tab
              title="JD & KRA"
              active
            >
              <jdKra
                v-if="subsidiary_id !== null"
                :subsidiary="subsidiary_id"
              />
            </b-tab>
            <b-tab :title="$t('Training & Development')">
              <trainDev
                v-if="subsidiary_id !== null"
                :subsidiary="subsidiary_id"
              />
            </b-tab>
            <b-tab
              v-if="roles.isAdmin"
              :title="$t('Internal / Industrial Involvement')"
            >
              <internal />
            </b-tab>
          </b-tabs>
          <!-- Control buttons-->
          <!-- <div class="d-flex justify-content-end mb-1 mr-2">
            <b-button-group class="mb-1">
              <b-button
                class=""
                style="
                background: #ffffff !important;
              border-color: red !important;
              color: red !important;
              "
                @click="tabIndex--"
              >
                {{ $t("Back") }}
              </b-button>
              <b-button
                class="ml-1 "
                variant="primary"
                @click="tabIndex++"
              >
                {{ $t("Next Page") }}
              </b-button>
            </b-button-group>
          </div> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {
  // BFormRadio,
  // BRow,
  // BCol,
  // BButton,
  // BButtonGroup,
  BTabs,
  BTab,
} from "bootstrap-vue"
import jdKra from '@/component/setting/jd-kra.vue'
import trainDev from '@/component/setting/training-development.vue'
import internal from '@/component/setting/internal-industrial.vue'
// import grade from '@/component/setting/grade.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    // BFormRadio,
    // BRow,
    // BCol,
    // BButton,
    // BButtonGroup,
    BTabs,
    BTab,
    jdKra,
    trainDev,
    internal,
    // grade,
  },

  data(){
    return {
      tabIndex: 1,
      subsidiary_id: null,
    }
  },

  computed: {
    ...mapGetters(['roles']),
  },

  mounted(){
    this.getSub_Id()
  },

  methods: {
    getSub_Id() {
      this.$axios.get(`${this.$baseUrl}/users/current_user`).then(response => {
        this.subsidiary_id = response.data.data.employee.business_unit
      })
    },
  },
}
</script>
