<template>
  <div
    class=""
  >
    <div
      class="card"
      style="background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 10px;
              overflow-y:auto;"
    >
      <div
        class="pl-3 pr-3 pb-2 pt-2"
      >
        <div
          class="row saj-title pl-1"
          style=""
        >
          {{ $t("Internal / Industrial Involvement Setting") }}
        </div>

        <!-- <div
          class="saj-text mt-1 mb-1"
        >
          <div>{{ $t("Choose one") }} (1) {{ $t("Performance Evaluation Form for Non Executive") }}:</div>
          <div style="color:red">
            {{ $t("Please select one evaluation for each company") }}
          </div>
        </div> -->
        <!-- <div class="mt-1 saj-title d-flex justify-content-end">
          <b-button
            class=""
            variant="primary"
            @click="updateJdKra()"
          >
            {{ $t("Save") }}
          </b-button>
        </div> -->
        <div
          class=""
        >
          <b-overlay
            :show="show"
            rounded="sm"
          >
            <template #overlay>
              <p>{{ $t('Fetching Data') }}... </p>
            </template>
            <div class="row mt-1 pl-1 pr-1">
              <b-table
                class="styleTable mt-1"
                style=""
                :items="list"
                :fields="fields"
                label-size="lg"
                bordered
                show-empty
                responsive
              >
                <template #head()="data">
                  <span
                    class="saj-text d-flex justify-content-center"
                  >{{ $t(data.label) }}</span>
                </template>
                <template #cell(index)="data">
                  <div
                    class="saj-text d-flex justify-content-center"
                  >
                    {{ currentPage === 1 ? data.index + 1 : (data.index + 1)+((currentPage) *10) }}
                  </div>
                </template>
                <template #cell(subsidiary_name)="data">
                  <div
                    class="saj-text d-flex justify-content-start"
                  >
                    {{ data.item.subsidiary_name }}
                  </div>
                </template>
                <template #cell(status)="data">
                  <div class="d-flex justify-content-end">
                    <feather-icon
                      v-if="list[data.index].isEdit"
                      v-b-tooltip.hover.bottom="$t('Cancel')"
                      class="onCancel"
                      icon="XIcon"
                      size="26"
                      style="height: 28px; color: #c9c9c9;"
                      @click="cancelEdit(data)"
                    />
                    <feather-icon
                      v-if="!list[data.index].isEdit"
                      v-b-tooltip.hover.bottom="$t('Edit')"
                      class="onEdit"
                      icon="Edit2Icon"
                      size="16"
                      style="height: 28px; color: #c9c9c9;"
                      @click="editRow(data)"
                    />
                    <feather-icon
                      v-if="list[data.index].isEdit"
                      v-b-tooltip.hover.bottom="$t('Save')"
                      class="onSave"
                      icon="SaveIcon"
                      size="26"
                      style="height: 28px; color: #c9c9c9; margin-left: 2px"
                      @click="$bvModal.show('save-status'), update = data.item"
                    />
                  </div>
                  <div
                    class="saj-text d-flex justify-content-center mb-1"
                    style=""
                  >
                    <div
                      v-if="!list[data.index].isEdit"
                      class="saj-text d-flex justify-content-start"
                    >
                      {{ $t(data.item.status) }}
                    </div>
                    <div
                      v-else
                      class=""
                    >
                      <b-row class="">
                        <b-form-radio
                          v-model="data.item.value"
                          plain
                          :name="jd"
                          value="enable"
                          class="saj-text"
                        >
                          <h1
                            class="saj-text"
                          >
                            {{ $t("Enable") }}
                          </h1>
                        </b-form-radio>
                      </b-row>
                      <b-row>
                        <b-form-radio
                          v-model="data.item.value"
                          plain
                          :name="kra"
                          value="disable"
                          class="saj-text"
                        >
                          <h1 class="saj-text">
                            {{ $t("Disable") }}

                          </h1>
                        </b-form-radio>
                      </b-row>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </b-overlay>
          <b-modal
            id="save-status"
            :hide-footer="true"
            :hide-header="true"
            :centered="true"
            :no-close-on-backdrop="true"
            size="lg"
          >
            <confirm
              :title="$t('Confirm to proceed?')"
              :subtitle="$t('It will affect the calculation of performance score.')"
              confirm-btn="Confirm"
              @cancel="$bvModal.hide('save-status')"
              @confirm="updateStatus()"
            />
          </b-modal>
          <!-- <div class="mt-1 saj-title d-flex justify-content-end">
            <b-button
              class=""
              variant="primary"
              @click="updateJdKra()"
            >
              {{ $t("Save") }}
            </b-button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BFormRadio,
  BRow,
  BTable,
  //   BCol,
  // BButton,
  BOverlay,
  // BButtonGroup,
  VBTooltip,
} from "bootstrap-vue"
import SAJToast from '@/component/saj-toastification.vue'

import { mapGetters } from 'vuex'
import confirm from '@/pages/alert-modal.vue'

export default {
  components: {
    BFormRadio,
    BRow,
    BTable,
    // BCol,
    // BButton,
    BOverlay,
    // BButtonGroup,
    confirm,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    subsidiary: {
      type: Number,
      default: null,
    },
  },

  data(){
    return {
      update: null,
      tabIndex: 1,
      show: true,
      currentPage: 1,
      // subsidiary_id: null,
      jd_kra: null,
      kra: null,
      jd: null,
      list: [],
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center',
        },
        {
          key: 'subsidiary_name', label: 'Company', tdClass: 'text-center',
        },
        {
          key: 'status', label: 'Status', tdClass: 'text-center',
        },
      ],
    }
  },

  computed: {
    ...mapGetters(['roles']),
  },

  mounted(){
    // console.log('sini sub id', this.subsidiary)
    this.getList()
  },

  methods: {
    updateStatus() {
      // console.log(this.update)
      const data = new FormData()
      data.append(`id`, this.update.id)
      // data.append(`jdkra[${item.subsidiary_id}]`, item.jdkra_id)
      // data.append(`subsidiary_id[]`, item.subsidiary_id)
      data.append(`status`, this.update.value)

      this.$axios.post(`${this.$baseUrl}/subsidiary/update_internal_industry_status`, data).then(() => {
        // this.show = false
        this.$bvModal.hide('save-status')
        this.getList()
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$i18n.t(`Successfully saved`)}.`,
              icon: 'CheckIcon',
              iconBg: '#53b7db',
              variant: 'success',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
      })
    },
    getList() {
      this.$axios.get(`${this.$baseUrl}/subsidiary/get_internal_industry_status`).then(response => {
        const res = response.data.data
        // console.log(res)

        // res.forEach(y => {
        //   console.log(y.internal_industry_involvement.charAt(0).toUpperCase() + y.internal_industry_involvement.slice(1))
        // })

        this.list = res.map(x => ({
          subsidiary_name: x.subsidiary_name,
          id: x.id,
          status: x.internal_industry_involvement.charAt(0).toUpperCase() + x.internal_industry_involvement.slice(1),
          value: x.internal_industry_involvement,
          isEdit: false,
        }))
        this.show = false
      })
    },
    editRow(data){
      this.list[data.index].isEdit = true
      // this.isEdit = true
    },
    cancelEdit(data){
      this.list[data.index].isEdit = false
      // this.isEdit = false
    },
  },
}
</script>
<style>
.radio .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    bottom: 0.5px;
    margin-left: -1.25rem;
}
</style>

<style scoped>
.onCancel:hover {
    color: red !important;
}

.onSave:hover {
    color: #21db4f !important;
}

.onEdit:hover {
    color: #faaf00 !important;
}
</style>
